import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user'
import MortgageEstimate from './modules/mortgage-estimate';
import SecureLS from 'secure-ls';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
const localStore = new SecureLS({
    encodingType: 'aes',
    isCompression: true,
});

export default new Vuex.Store({
    modules: { User, "mortgage-estimate": MortgageEstimate, },
    plugins: [createPersistedState({
        paths: ['User'],
        storage: {
            getItem: key => localStore.get(key),
            setItem: (key, value) => localStore.set(key, value),
            removeItem: key => localStore.remove(key),
        },
    })],
});
