<template>
  <div class="content__wrapper">
    <more-info-dialog
      :moreInfoData="moreInfoData"
      :paymentBreakdownChart="paymentBreakdownChart"
      :cashToCloseBreakdownChart="cashToCloseBreakdownChart"
      :scenarioKeyMap="scenarioKeyMap"
      :all-scenarios="scenariosDataNew"
      :detailSP="detailSP"
      @learnMore="learnMore"
      ref="moreInfo"
    />
    <fee-detail-dialog
      :mortgageData="scenariosDataView"
      @closeDetail="moreInfo(1)"
      ref="feeDetail"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import camelCase from "lodash/camelCase";
import moment from "moment/moment";

export default {
  name: "MortgageEstimate",
  components: {
    FeeDetailDialog: () =>
      import("../components/mortgage-estimate/FeeDetailDialog.vue"),
    MoreInfoDialog: () =>
      import("../components/mortgage-estimate/MoreInfoDialog.vue"),
  },

  data() {
    return {
      detailSP: [],
      moreInfoData: {
        paymentBreakdown: [],
        cashToCloseBreakdown: [],
        paymentBreakdownField: [
          {
            key: "paymentBreakdown",
            label: "Payment breakdown",
          },
        ],
        cashToCloseBreakdownField: [
          {
            key: "cashToCloseBreakdown",
            label: "Cash to close",
          },
        ],
      },
      paymentBreakdownChart: [],
      cashToCloseBreakdownChart: [],
      scenarioKeyMap: {},
    };
  },

  computed: {
    ...mapGetters("mortgage-estimate", [
      "scenariosData",
      "getQuoteDate",
      "showPayingOffDebts",
      "getOpenModal",
    ]),

    scenariosDataView() {
      const scenarioId = this.$route.query["scenario-id"];
      return scenarioId
        ? this.scenariosData.filter((item) => item.id === scenarioId)
        : this.scenariosData;
    },

    scenariosDataNew() {
      return this.scenariosDataView;
    },

    quoteDate() {
      return moment(this.getQuoteDate).format("ll");
    },
  },

  watch: {
    showPayingOffDebts(newVal) {
      if (newVal) {
        this.scenariosDataView.forEach((rec, index) => {
          this.paymentBreakdownChart[index].debts =
            rec.paymentBreakdown.additionalDebts;
        });
      } else {
        this.scenariosDataView.forEach((rec, index) => {
          this.paymentBreakdownChart[index] = Object.fromEntries(
            Object.entries(this.paymentBreakdownChart[index]).filter(
              ([key]) => key !== "additionalDebts"
            )
          );
        });
      }
    },

    scenariosDataView: {
      handler(val) {
        this.handleMoreInfoInCard(val);
      },
      deep: true,
    },
    getOpenModal: {
      handler(val) {
        if (val) {
          this.moreInfo();
        }
      },
      deep: true,
    },
  },

  methods: {
    handleMoreInfoInCard(val) {
      this.moreInfoData.paymentBreakdownField = [
        {
          key: "paymentBreakdown",
          label: "Payment breakdown",
        },
      ],
      this.moreInfoData.cashToCloseBreakdownField = [
          {
            key: "cashToCloseBreakdown",
            label: "Cash to close",
          },
        ],  
        this.detailSP = this.scenariosDataView.map((e) => ({
          name: e.name,
          ufmipFfGfSp: e.cashToCloseBreakdown.ufmipFfGfSp,
          ufmipFfGfSpLabel: e.cashToCloseBreakdown.ufmipFfGfSpLabel,
        }));

      val.forEach((e) => {
        this.scenarioKeyMap[camelCase(e.name)] = e.name;
      });
      
      // handle get field
      this.moreInfoData.paymentBreakdownField = this.handleGetField(
        this.moreInfoData.paymentBreakdownField,
        val
      );

      this.moreInfoData.cashToCloseBreakdownField = this.handleGetField(
        this.moreInfoData.cashToCloseBreakdownField,
        val
      );

      const paymentBreakdownKeyMap = {
        purchasePrice: "Purchase Price",
        pi: "P&I (1st)",
        propertyTaxes: "Property Taxes",
        hoi: "HOI",
        pmi: "PMI",
        monthlyPayment: "Monthly Payment",
        hoa: "HOA",
        totalDebtPayment: "Additional Debt(s)",
        totalPayment: "Total Payment",
      };

      const paymentRows = [
        { paymentBreakdown: "Purchase Price" },
        { paymentBreakdown: "P&I (1st)" },
        { paymentBreakdown: "Property Taxes" },
        { paymentBreakdown: "HOI" },
        { paymentBreakdown: "PMI" },
        { paymentBreakdown: "Monthly Payment" },
        { paymentBreakdown: "HOA" },
        { paymentBreakdown: "Additional Debt(s)" },
        { paymentBreakdown: "Total Payment" },
      ];

      this.moreInfoData.paymentBreakdown = this.handleGetInfoData(
        this.scenarioKeyMap,
        paymentBreakdownKeyMap,
        paymentRows,
        val,
        "paymentBreakdown"
      );

      const cashToCloseKeyMap = {
        downPmt: "Down Pmt",
        loanToValue: "Loan to Value",
        closingCost: "Closing Costs",
        ufmipFfGfSp: "SP",
        prepaid: "Prepaids",
        earnestMoney: "Earnest Money",
        cashToClose: "Cash to Close",
        feeDetails: "Fee Detail",
      };

      const cashRows = [
        { cashToCloseBreakdown: "Down Pmt" },
        { cashToCloseBreakdown: "Loan to Value" },
        { cashToCloseBreakdown: "Closing Costs" },
        { cashToCloseBreakdown: "SP" },
        { cashToCloseBreakdown: "Prepaids" },
        { cashToCloseBreakdown: "Earnest Money" },
        { cashToCloseBreakdown: "Cash to Close" },
        { cashToCloseBreakdown: "Fee Detail" },
      ];

      this.moreInfoData.cashToCloseBreakdown = this.handleGetInfoData(
        this.scenarioKeyMap,
        cashToCloseKeyMap,
        cashRows,
        val,
        "cashToCloseBreakdown"
      );

      this.paymentBreakdownChart = val.map((e) => ({
        pi: e.paymentBreakdown.pi,
        propertyTaxes: e.paymentBreakdown.propertyTaxes,
        hoi: e.paymentBreakdown.hoi,
        pmi: e.paymentBreakdown.pmi,
        ...(this.showPayingOffDebts && {
          debts: e.paymentBreakdown.additionalDebts ?? 0,
        }),
      }));

      this.cashToCloseBreakdownChart = val.map((e) => ({
        downPmt: e.cashToCloseBreakdown.downPmt,
        closingCost: e.cashToCloseBreakdown.closingCost,
        points: e.cashToCloseBreakdown.points,
        sp: e.cashToCloseBreakdown.sp,
        prepaid: e.cashToCloseBreakdown.prepaid,
      }));
    },

    moreInfo(tabIndex) {
      this.$refs.moreInfo.open(tabIndex);
    },
    learnMore(items) {
      this.$refs.moreInfo.close();
      this.$refs.feeDetail.open(items);
    },
    handleGetField(field, mortgageData) {
      const fieldData = [
        ...field,
        ...mortgageData.map((e) => ({
          key: camelCase(e.name),
          label: e.name,
          sortable: true,
        })),
      ];

      return fieldData;
    },

    handleGetInfoData(
      scenarioKeyMap,
      breakdownKeyMap,
      rows,
      mortgageData,
      field
    ) {
      mortgageData.map((e) => {
        const scenarioIdx = Object.values(scenarioKeyMap).findIndex(
          (name) => name === e.name
        );
        const scenarioKey = Object.keys(scenarioKeyMap)[scenarioIdx];

        rows.forEach((r) => {
          const breakdownIdx = Object.values(breakdownKeyMap).findIndex(
            (name) => name === r[field]
          );
          const breakdownKey = Object.keys(breakdownKeyMap)[breakdownIdx];
          r[scenarioKey] = e[field][breakdownKey];
        });
      });
      return rows;
    },
  },
};
</script>

<style scoped lang="scss">
.content__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  background: #ffffff;
}

.mortgage-section {
  &--header {
    display: flex;
    justify-content: space-between;
    gap: 25px;
  }

  &--content {
    display: flex;
    gap: 50px;
    padding: 30px 34px;
    @media all and (max-width: 767px) {
      padding: 80px 34px 34px 34px;
    }
  }

  &--footer {
    padding: 0 35px;
  }

  &--left {
    width: 50%;
    height: fit-content;
  }

  &--right {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-between;
    height: auto;
  }
}

.mortgage-card--wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-header {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 4px;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 30px;
    opacity: 0.6;
  }
}

.footer-layout {
  background: #ffffff;
}

.more-info-btn {
  border-radius: 25px;
  white-space: nowrap;
  margin-top: 8px;
}

@media (max-width: 1263px) {
  .mortgage-section {
    &--content {
      flex-direction: column;
    }
    &--left {
      width: 100%;
      margin: auto;
    }
    &--right {
      width: 100%;
      margin: auto;
    }
  }
}

@media (max-width: 599px) {
  .mortgage-section--left {
    .mortgage-section--header {
      max-width: 230px;
    }
  }
  .mortgage-section {
    &--header {
      flex-direction: column;
      gap: 0;
      .info-header__description {
        margin-bottom: 16px;
      }
      .more-info-btn {
        margin-top: 0;
        margin-bottom: 24px;
        width: fit-content;
      }
    }
    &--content {
      gap: 40px;
      padding: 108px 14px 50px 14px;
    }
    &--footer {
      padding: 0;
    }
  }

  .mortgage-card {
    &--wrap {
      display: flex;
      flex-direction: row;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}
</style>
