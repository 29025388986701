<template>
  <div :class="show ? 'chart-legend-active' : ''" class="chart-legend">
    <p v-for="(legend, index) in legends" :key="index">
      <span
        class="chart-legend--color"
        :style="{ backgroundColor: legend.color }"
      />
      <span class="chart-legend--text">{{ legend.text }}</span>
      <info-tooltip :info="legend.info" />
    </p>
  </div>
</template>

<script>
import InfoTooltip from "@/components/Icons/InfoToolTip";

export default {
  name: "ChartLegend",
  components: { InfoTooltip },
  props: {
    legends: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-legend {
  background: #ffffff;
  border-radius: 12px;
  padding: 28px 0 28px 22px;
  height: fit-content;
  position: relative;
  opacity: 0;
  transition: opacity 1s;
  min-width: 208px;
  p {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  &--color {
    border-radius: 2px;
    padding: 2.5px;
    margin-right: 12px;
  }
  &--text {
    color: #000000;
  }
}

.chart-legend-active {
  opacity: 1;
}
@media (max-width: 767px) {
  .chart-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .chart-legend > * {
    margin-right: 10px;
  }
}
</style>
