<template>
  <div class="h-100">
    <loader v-if="loading" />

    <div v-else class="verification-screen">
      <img
        v-if="teamLogo"
        :src="teamLogo"
        class="text-center mb-5 logo"
        alt="Team-Logo"
      />
      <div class="two-factor-auth">
        <div v-if="!codeSent" class="box">
          <div class="title-desc">
            <h3 class="title">Verification</h3>
            <p class="desc">
              We will send a verification code to your<br />
              {{
                sendCodeMethod === "message" ? "phone number" : "email address"
              }}
              to access your portal
            </p>
          </div>

          <div class="request-code">
            <h2>{{ sendCodeMethod === "message" ? userPhone : userEmail }}</h2>
            <a
              role="button"
              class="btn btn-primary"
              @click="
                codeSent = true;
                getOtp();
              "
              >Send code</a
            >
          </div>

          <div class="text-button">
            <p class="text-secondary">or choose another method</p>
            <div class="button-link">
              <p
                role="button"
                class="btn-link"
                @click="
                  codeSent = true;
                  sendCodeMethod = 'email';
                  getOtp();
                "
              >
                Receive an email
              </p>

              <p
                role="button"
                class="btn-link"
                @click="
                  codeSent = true;
                  sendCodeMethod = 'message';
                  getOtp();
                "
              >
                Receive a text
              </p>
            </div>
          </div>

          <div class="form-group mt-4 mb-3" v-if="assistantExists">
            <label class="form-label float-left text-secondary"
              >Teammate Login</label
            >
            <select
              class="form-select"
              v-model="assistantId"
              aria-label="Default select example"
            >
              <option value="None">None</option>
              <option
                v-for="(assistant, index) in loginAssistants"
                :key="index"
                :value="assistant.assistantId"
              >
                {{ assistant.assistantName }}
              </option>
            </select>
          </div>
        </div>

        <div v-else class="box">
          <div class="title-desc">
            <h3 class="title">Enter your verification code</h3>
            <p class="desc">
              Provide the verification code sent to
              {{ sendCodeMethod === "message" ? userPhone : userEmail }} to
              access your portal
            </p>
          </div>
          <div class="code-input">
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="firstDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="firstDigit"
              v-model="firstDigit"
              @input="
                firstDigit && !secondDigit ? $refs.secondDigit.focus() : ''
              "
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="secondDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="secondDigit"
              v-model="secondDigit"
              @input="
                secondDigit && !thirdDigit ? $refs.thirdDigit.focus() : ''
              "
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="thirdDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="thirdDigit"
              v-model="thirdDigit"
              @input="
                thirdDigit && !fourthDigit ? $refs.fourthDigit.focus() : ''
              "
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="fourthDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="fourthDigit"
              v-model="fourthDigit"
              @input="
                fourthDigit && !fifthDigit ? $refs.fifthDigit.focus() : ''
              "
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="fifthDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="fifthDigit"
              v-model="fifthDigit"
              @input="fifthDigit && !sixthDigit ? $refs.sixthDigit.focus() : ''"
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="sixthDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="sixthDigit"
              v-model="sixthDigit"
              @input="sixthDigit ? $refs.verify.focus() : ''"
            />
          </div>
          <p v-if="errorMessage" class="error-msg">{{ errorMessage }}</p>

          <div class="mt-4 request-code">
            <button ref="verify" class="btn btn-primary" @click="verifyOtp()">
              Verify
            </button>
            <p class="mt-4">
              Still haven't received a code? Has your code expired?
            </p>
            <button role="button" class="mt-2 btn-link" @click="getOtp()">
              Resend Code
            </button>
          </div>
          <div class="text-button">
            <p class="text-secondary">or choose another method</p>
            <div class="button-link">
              <p
                role="button"
                class="btn-link"
                @click="
                  sendCodeMethod = 'email';
                  getOtp();
                "
              >
                Receive an email
              </p>

              <p
                role="button"
                class="btn-link"
                @click="
                  sendCodeMethod = 'message';
                  getOtp();
                "
              >
                Receive a text
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MortgageEstimate class="hidden" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toasters from "../mixins/toasters";
import Loader from "./Loader";
import { eventBus } from "./../event-bus";
import axios from "@/api-service";
import { cloneDeep } from "lodash";
import MortgageEstimate from "./MortgageEstimate.vue";

export default {
  name: "Two-Factor-Auth",
  components: {
    Loader,
    MortgageEstimate,
  },
  mixins: [Toasters],
  data() {
    return {
      codeSent: false,
      prospectId: "",
      orgId: "",
      assistantId: "None",
      loginAssistants: [],
      loading: false,
      userEmail: "",
      userPhone: "",
      firstDigit: "",
      secondDigit: "",
      thirdDigit: "",
      fourthDigit: "",
      fifthDigit: "",
      sixthDigit: "",
      respOtpCode: "",
      sendCodeMethod: "message",
      errorMessage: "",
      teamLogo: "",
      portalBg: "",
      clientName: "",
    };
  },
  computed: {
    ...mapGetters(["getAssets", "getUser"]),

    assistantExists() {
      return this.loginAssistants.length ? true : false;
    },

    otpCode() {
      return Number(
        this.firstDigit +
          this.secondDigit +
          this.thirdDigit +
          this.fourthDigit +
          this.fifthDigit +
          this.sixthDigit
      );
    },
  },
  watch: {
    portalBg(newVal) {
      if (newVal) {
        document.body.style.backgroundImage = `url(${newVal})`;
        document.body.style.backgroundPosition = "top-center";
        document.body.style.backgroundSize = "cover";
      }
    },

    assistantId(newVal) {
      if (newVal) {
        if (newVal === "None") {
          this.hideThePhone(this.getUser.accountPhone);
          this.hideTheEmail(this.getUser.accountEmail);
          return;
        }

        let selectedAssistant = this.loginAssistants.find(
          (x) => x.assistantId === newVal
        );

        if (selectedAssistant) {
          this.hideThePhone(selectedAssistant.assistantPhone);
          this.hideTheEmail(selectedAssistant.assistantEmail);
        }
      }
    },
  },
  async beforeMount() {
    let theUrl = this.$route.params.unique_key.split("&");

    this.prospectId = theUrl[0];
    this.orgId = theUrl[1];

    await this.getPortalAssets();

    this.fetchIsCoPilot(false);

    if (this.$route.query.platform === "salesforce") await this.autoLogin();
    else await this.getOtp();
  },
  methods: {
    ...mapActions([
      "fetchProspectId",
      "fetchOrgId",
      "fetchUser",
      "fetchAssets",
      "fetchIsCoPilot",
    ]),
    ...mapActions("mortgage-estimate", ["getScenarios", "SET_CLIENT_NAME"]),
    async getPortalAssets() {
      this.loading = true;

      await this.$http
        .get("/accountAuth/GetAsset/" + this.prospectId + "/" + this.orgId)
        .then((response) => {
          let resp = response.data;

          if (resp.status === 511) this.$router.push("/");

          if (resp.status !== 511) {
            document.title = resp?.assetData?.loCompany
              ? resp.assetData.loCompany + " Realtor Portal"
              : "Realtor Portal";

            this.teamLogo = resp.assetData.horizontalTeamLogo;
            this.portalBg = resp.assetData.agentPortalBackground;
            this.loginAssistants = resp.assetData.assistantData;

            const favicon = document.getElementById("favicon");
            favicon.setAttribute("href", resp.assetData?.roundColorTeamLogo);

            this.fetchAssets(resp.assetData);
            this.fetchProspectId(this.prospectId);
            this.fetchOrgId(this.orgId);

            this.$localStore.set(
              "primary-color",
              resp.assetData.primaryBrandColor || "#A6383C"
            );

            document.body.style.setProperty(
              "--primary-color",
              resp.assetData.primaryBrandColor || "#A6383C",
              "important"
            );

            this.$localStore.set("default-tabs", resp.assetData.defaultTabs);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getOtp() {
      const skipNoti = this.codeSent ? false : true;

      this.loading = true;

      this.firstDigit = "";
      this.secondDigit = "";
      this.thirdDigit = "";
      this.fourthDigit = "";
      this.fifthDigit = "";
      this.sixthDigit = "";

      await this.$http
        .get(
          "/accountAuth/" +
            this.prospectId +
            "&&" +
            this.sendCodeMethod +
            "?orgId=" +
            this.orgId +
            "&assistantId=" +
            this.assistantId +
            "&skipNotification=" +
            skipNoti
        )
        .then((response) => {
          let resp = response.data;

          if (resp.status !== 511) {
            this.errorMessage = "";

            if (resp?.authenticationData?.accountOTPCode && this.codeSent) {
              this.success("Verification code sent by " + this.sendCodeMethod);

              this.respOtpCode = Number(
                window.atob(resp.authenticationData.accountOTPCode)
              );
              console.log( this.respOtpCode," this.respOtpCode")
            }

            if (this.assistantId && this.assistantId === "None") {
              if (resp?.authenticationData?.accountEmail)
                this.hideTheEmail(resp.authenticationData.accountEmail);

              if (resp?.authenticationData?.accountPhone)
                this.hideThePhone(resp.authenticationData.accountPhone);
            }

            this.fetchUser(resp.authenticationData);

            this.loading = false;

            if (this.codeSent) {
              this.$nextTick(() => {
                this.$refs.firstDigit.focus();
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);

          this.loading = false;

          this.$nextTick(() => {
            this.$refs.firstDigit.focus();
          });
        });
    },

    async verifyOtp() {
      if (this.otpCode.toString().length !== 6) {
        const type =
          this.sendCodeMethod === "message" ? "phone number" : "email address";

        this.errorMessage = `Please enter the One Time Code sent on your ${type}`;
        return;
      }

      if (this.respOtpCode === this.otpCode) {
        this.$localStore.set("authorized", true);

        if (
          Object.keys(this.$route?.query).length &&
          this.$route.query?.recordId
        ) {
          let validRecordId = await this.getPipelineData(
            this.$route.query?.recordId
          );
          if (validRecordId) {
            eventBus.$emit("loader-status", true);
            const { data = {} } = await axios.get(
              "https://clientportalapi.candidcrm.com/scenarios",
              {
                params: {
                  isBorrower: true,
                  orgId: this.orgId,
                  recordId: this.$route.query?.recordId,
                },
              }
            );
            eventBus.$emit("loader-status", false);
            let mortgageReviewData = cloneDeep(data);
            this.$store.dispatch("mortgage-estimate/SET_OPEN_MODAL", true);

            this.$nextTick(() => {
              this.getScenarios(mortgageReviewData);
            });
            this.$nextTick(() => {
              eventBus.$emit("modalOpen", true);
            });
            this.$router.push({ name: "Pipeline" });
            return;
          } else {
            this.$router.push({ name: "Pipeline" });
            return;
          }
        }

        if (
          Object.keys(this.$route.query).length &&
          this.$route.query.defaultTab &&
          this.getAssets.defaultTabs.includes(this.$route.query.defaultTab)
        ) {
          this.$router.push({ name: this.$route.query.defaultTab });
          return;
        }

        if (this.getAssets.defaultTabs.includes(this.getAssets.pinnedTab)) {
          this.$router.push({
            name: this.getAssets.pinnedTab || "Approval-Letters",
          });
          return;
        }

        if (!this.getAssets.defaultTabs.includes(this.getAssets.pinnedTab)) {
          this.$router.push({
            name: this.getAssets.defaultTabs[0],
          });
          return;
        }
      }

      if (this.respOtpCode !== this.otpCode) {
        this.errorMessage =
          "Entered code is not valid. Please check your input or try resending the code.";

        this.firstDigit = "";
        this.secondDigit = "";
        this.thirdDigit = "";
        this.fourthDigit = "";
        this.fifthDigit = "";
        this.sixthDigit = "";

        this.$refs.firstDigit.focus();
      }
    },

    async autoLogin() {
      await this.$http
        .get(
          "/accountAuth/" +
            this.prospectId +
            "&&" +
            this.sendCodeMethod +
            "?orgId=" +
            this.orgId +
            "&assistantId=" +
            this.assistantId +
            "&skipNotification=true"
        )
        .then(async (response) => {
          let resp = response.data;

          if (resp.status !== 511) {
            this.fetchIsCoPilot(true);
            this.fetchUser(resp.authenticationData);
            this.$localStore.set("authorized", true);

            if (
              Object.keys(this.$route?.query).length &&
              this.$route.query?.recordId
            ) {
              let validRecordId = await this.getPipelineData(
                this.$route.query?.recordId
              );
              if (validRecordId) {
                eventBus.$emit("loader-status", true);
                const { data = {} } = await axios.get(
                  "https://clientportalapi.candidcrm.com/scenarios",
                  {
                    params: {
                      isBorrower: true,
                      orgId: this.orgId,
                      recordId: this.$route.query?.recordId,
                    },
                  }
                );
                eventBus.$emit("loader-status", false);
                let mortgageReviewData = cloneDeep(data);
                this.$store.dispatch("mortgage-estimate/SET_OPEN_MODAL", true);

                this.$nextTick(() => {
                  this.getScenarios(mortgageReviewData);
                });
                this.$nextTick(() => {
                  eventBus.$emit("modalOpen", true);
                });
                this.$router.push({ name: "Pipeline" });
                return;
              } else {
                this.$router.push({ name: "Pipeline" });
                return;
              }
            }

            if (
              Object.keys(this.$route.query).length &&
              this.$route.query.defaultTab &&
              this.getAssets.defaultTabs.includes(this.$route.query.defaultTab)
            ) {
              this.$router.push({ name: this.$route.query.defaultTab });
              return;
            }

            if (this.getAssets.defaultTabs.includes(this.getAssets.pinnedTab)) {
              this.$router.push({
                name: this.getAssets.pinnedTab || "Approval-Letters",
              });
              return;
            }

            if (
              !this.getAssets.defaultTabs.includes(this.getAssets.pinnedTab)
            ) {
              this.$router.push({
                name: this.getAssets.defaultTabs[0],
              });
              return;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.loading = false;
    },

    async getPipelineData(recordId) {
      try {
        const response = await this.$http.get(
          "/pipeline/getPipelineData/" +
            this.prospectId +
            "/All?orgId=" +
            this.orgId
        );

        let resp = response?.data;
        let clientShowScenarios = resp?.showScenarios;

        if (clientShowScenarios && clientShowScenarios.length > 0) {
          // Check if recordId exists in clientShowScenarios
          let checkShowScenario = clientShowScenarios?.find((item) => {
            if (item?.prospectOrDealId === recordId) {
              this.clientName = item?.clientName; // Assign the clientName here
              return true; // Return true to indicate a match
            }
            return false; // Return false for non-matching items
          });

          if (checkShowScenario) {
            // If recordId exists, check showScenarios
            let result = checkShowScenario?.showScenarios;
            this.SET_CLIENT_NAME(this.clientName);

            return result; // Return result from here
          } else {
            // If recordId is not found
            return false;
          }
        } else {
          // If clientShowScenarios is empty or undefined
          return false;
        }
      } catch (error) {
        console.log(error);
        return false; // Ensure a fallback value in case of an error
      }
    },

    hideTheEmail(email) {
      let index = email.indexOf("@");
      let startIndex = (index * 0.2) | 0;
      let endIndex = (index * 1) | 0;

      this.userEmail =
        email.slice(0, startIndex) +
        email.slice(startIndex, endIndex).replace(/./g, "*") +
        email.slice(endIndex);
    },

    hideThePhone(phone) {
      this.userPhone = phone
        .replace(/\D+/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "xxx-xxx-$3");

      // let phone_number = phone.replace(/-/g, "");
      // this.userPhone = phone_number.replace(/.(?=.{4})/g, "x");
    },
  },
};
</script>

<style>
@import "./../assets/css/two-factor-auth.css";
</style>
