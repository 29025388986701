<template>
  <b-modal
    v-model="modal"
    modal-class="more-info-modal"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <h1 class="client-name">{{ clientName }}</h1>
    <close-button @click="close" />
    <div class="more-info-content" :style="cssProps">
      <base-tabs
        v-model="tab"
        :items="tabs"
        :start-from="tab === 1 ? '189px' : ''"
        is-start-left
      />
      <div class="more-info-content--tab">
        <payment-breakdown-tab
          v-if="tab === 0"
          :paymentBreakdown="moreInfoData.paymentBreakdown"
          :scenarioKeyMap="scenarioKeyMap"
          :paymentBreakdownChart="paymentBreakdownChart"
          :paymentBreakdownField="moreInfoData.paymentBreakdownField"
          :all-scenarios="allScenarios"
        />
        <cash-to-close-tab
          v-else
          :cashToCloseBreakdown="moreInfoData.cashToCloseBreakdown"
          :cashToCloseBreakdownChart="cashToCloseBreakdownChart"
          :scenarioKeyMap="scenarioKeyMap"
          :cashToCloseBreakdownField="moreInfoData.cashToCloseBreakdownField"
          :detailSP="detailSP"
          :isAMonth="false"
          @detail="detail"
          :all-scenarios="allScenarios"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import CloseButton from "../common/CloseButton.vue";
import PaymentBreakdownTab from "../mortgage-estimate/PaymentBreakdownTab.vue";
import CashToCloseTab from "../mortgage-estimate/CashToCloseTab.vue";
import BaseTabs from "../mortgage-estimate/BaseTabs.vue";
import Shades from "../../mixins/shades";
import { mapGetters } from "vuex";
import { eventBus } from "../../event-bus";


export default {
  name: "MoreInfoDialog",
  mixins: [Shades],
  components: {
    CashToCloseTab,
    CloseButton,
    PaymentBreakdownTab,
    BaseTabs,
  },
  props: {
    moreInfoData: {
      type: Object,
      default: () => {},
    },
    paymentBreakdownChart: {
      type: Array,
      default: () => [],
    },
    cashToCloseBreakdownChart: {
      type: Array,
      default: () => [],
    },
    scenarioKeyMap: {
      type: Object,
      default: () => {},
    },
    allScenarios: {
      type: Array,
      default: () => [],
    },
    detailSP: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modal: false,
      show: false,
      tab: 0,
      tabs: [
        { id: 0, name: "Payment breakdown" },
        { id: 1, name: "Cash to close breakdown" },
      ],
    };
  },
  mounted() {
    eventBus.$on("modalOpen", (data) => {
      if(data){
        this.open()
      }
    });
  },
  methods: {
    open(tabIndex) {
      if (tabIndex !== undefined) {
        this.tab = 1;
      }
      this.modal = true;
    },
    close() {
      this.modal = false;
      this.$store.dispatch("mortgage-estimate/SET_OPEN_MODAL", false);
    },
    detail(items) {
      this.$emit("learnMore", items);
    },
  },
  watch: {
    modal(val) {
      if (val) return;
      if (!val) {
      setTimeout(() => {
        this.tab = 0;
      }, 300);
    }
    },
    // getOpenModal: {
    //   handler(val) {
    //     if (val) {
    //       this.open();
    //     }
    //   },
    //   // immediate: true,
    //   deep: true,
    // },
  },
  computed: {
    ...mapGetters(["getAssets"]),
    ...mapGetters("mortgage-estimate", {
      clientName: "getClientName",
    }),
    ...mapGetters("mortgage-estimate", [
      "getOpenModal",
    ]),
    
    cssProps() {
      return {
        "--primary-color": this.getAssets.primaryBrandColor || "#c34132",
        "--bg-primary-color": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 2)
          : "#FFCCCF",
        "--blink-shade": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 1.2)
          : "#eb1c2b",
        "--shade-one": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          -0.1
        ),
        "--shade-two": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          -0.3
        ),
        "--shade-three": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          0
        ),
        "--shade-four": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          -0.2
        ),
        "--poor": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 1.4)
          : "#FFCCCF",
        "--fair": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 0.9)
          : "#FF7B84",
        "--good": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, -0.1)
          : "#D1202D",
        "--very-good": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, -0.3)
          : "#D1202D",
        "--exceptional": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, -0.5)
          : "#94000B",
      };
    },
  },
};
</script>

<style lang="scss">
.modal {
  @media all and (max-width: 767px) {
    margin-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

.more-info-modal {
  .modal-dialog,
  .modal-content {
    width: 100% !important;
    max-width: max-content !important;
  }

  .modal-content {
    border-radius: 10px;
  }

  .modal-body {
    padding: 0 !important;
  }

  .more-info-content {
    padding: 20px 40px 40px 40px;
    &--tab {
      border-top: 1px solid #d7dae3;
      padding-top: 15px;
      margin-top: 15px;
      width: 100%;
    }
  }
}
@media all and (max-width: 1079px) {
  .more-info-modal {
    .modal-dialog {
      width: 100% !important;
      max-width: calc(100% - 28px) !important;
      margin: auto;
      padding: 70px 0 30px 0;
    }
    .modal-content {
      max-width: 100% !important;
    }
    .b-btn-close {
      right: 0;
      top: -40px;
    }
  }
}

@media all and (max-width: 599px) {
  .more-info-modal {
    .more-info-content {
      padding: 20px 14px;
      &--tab {
        border: unset;
        margin-top: 4px;
        padding-top: 0;
      }
    }
  }
}
.client-name {
  font-family: "Segoe UI", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  padding: 20px 40px 5px;
}
</style>
