<template>
  <span v-if="info" :data-text="info" class="info-tooltip ml-10">
    <info-tooltip-icon
      :color="'#C5C5C5'"
      v-b-tooltip.hover.topright.html="{
        customClass: 'tooltip-custom',
        title: info,
      }"
    />
  </span>
</template>

<script>
import InfoTooltipIcon from "../Icons/InfoTooltipIcon.vue";

export default {
  name: "InfoTooltip",
  components: { InfoTooltipIcon },
  props: {
    info: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.info-tooltip {
  display: inline-block;
}
</style>
