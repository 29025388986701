import Vue from "vue";
import moment from "moment";
moment.locale("en");

Vue.filter("formatDate", (date) => {
  return moment(new Date(date)).format("MMM D, YYYY");
});

Vue.filter("formatNumber", (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("capitalize", (word) => {
  return word.replace(/\b\w/g, (l) => l.toUpperCase());
});

Vue.filter("currencyNonDollar", (value, isAbsolute, format = "USD") => {
  if (value === undefined) {
    return "-";
  }
  const valueFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: format,
  })
    .format(value)
    .replace("$", "");

  return typeof value !== "string"
    ? isAbsolute
      ? valueFormatter.slice(0, -3)
      : valueFormatter
    : value;
});

Vue.filter("currencyAbsolute", (value, format = "USD") => {
  if (value === undefined) {
    return "-";
  }
  const valueFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: format,
  }).format(value);

  return typeof value !== "string" ? valueFormatter.slice(0, -3) : value;
});

Vue.filter("currencyOrPercent", (value, name, format = "USD") => {
  if (value === undefined) {
    return "-";
  }

  const valueFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: format,
    minimumFractionDigits: 2,
  }).format(value);

  if (
    name?.cashToCloseBreakdown === "Loan to Value" &&
    value === "Loan to Value"
  ) {
    return value;
  } else if (
    name?.cashToCloseBreakdown === "Loan to Value" &&
    value !== "Loan to Value"
  ) {
    return `${value}%`;
  } else if (
    (name?.cashToCloseBreakdown === "Seller Credit(s)" &&
      value !== "Seller Credit(s)") ||
    (name?.cashToCloseBreakdown === "Earnest Money" &&
      value !== "Earnest Money")
  ) {
    if (valueFormatter.slice(0, -3) !== "$0") {
      return `-${valueFormatter.slice(0, -3)}`;
    } else {
      return `${valueFormatter.slice(0, -3)}`;
    }
  } else if (typeof value !== "string") {
    return valueFormatter.slice(0, -3);
  } else {
    return value;
  }
});
