/* eslint-disable */
import camelCase from "lodash/camelCase";
import axios from "@/api-service";

const state = {
  scenariosData: [],
  quoteDate: null,
  payingOffDebts: true,
  debtDetailsScenarios: [],
  debtDetailsTooltip: [],
  debtRecords: 0,
  openModal: false ,
  clientName: ""
};

const getters = {
  scenariosData(state) {
    return state.scenariosData || [];
  },

  getQuoteDate(state) {
    return state.quoteDate;
  },

  getDebtRecords(state) {
    return state.debtRecords
  },
  
  getDebtDetailsScenarios(state) {
    return state.debtDetailsScenarios
  },

  getDebtTooltip(state) {
    return state.debtDetailsTooltip
  },

  showPayingOffDebts(state) {
    return state.payingOffDebts;
  },

  getOpenModal(state) {
    return state.openModal;
  },
  getClientName(state) {
    return state.clientName;
  }
};

const mutations = {
  SET_SCENARIOS(state, val) {
    state.scenariosData = val || [];
  },

  SET_QUOTE_DATE(state, val) {
    state.quoteDate = val;
  },

  SET_DEBT_DETAILS_SCENARIOS(state, val) {
    state.debtDetailsScenarios = val
  },


  SET_DEBT_RECORDS(state, val) {
    state.debtRecords = val
  },

  SET_DEBT_TOOLTIP(state, val) {
    state.debtDetailsTooltip = val
  },

  SET_PAYING_OFF_DEBTS(state, val) {
    state.payingOffDebts = val;
  },
  SET_MODAL(state, val) {
    state.openModal = val;
  },
  SET_NAME(state, val) {
    state.clientName = val
  }
};

const actions = {
    getScenarios({ commit }, data) {
      commit('SET_SCENARIOS', data?.scenarios)
      commit("SET_QUOTE_DATE", data?.newQuoteDate);
      commit("SET_DEBT_DETAILS_SCENARIOS", data?.debtDetailByScenarios || [])
      commit("SET_DEBT_RECORDS", data?.totalDebtRecords || 0)
      commit("SET_DEBT_TOOLTIP", data?.debtDetailsTooltip || [])
  },

  fetchPayingOffDebts({ commit }, data) {
    commit("SET_PAYING_OFF_DEBTS", data);
  },
  SET_OPEN_MODAL({ commit }, val) {
    commit("SET_MODAL", val); 
  },
  SET_CLIENT_NAME({ commit }, val) {
    commit("SET_NAME", val); 
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
``