export default {
    methods: {
        getShadesOfColor(color, lum) {
            // validate hex string
            color = String(color).replace(/[^0-9a-f]/gi, "");

            if (color.length < 6) {
                color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
            }

            lum = lum || 0;

            // convert to decimal and change luminosity
            let rgb = "#";
            let c;

            if (color) {
                for (let i = 0; i < 3; i++) {
                    c = parseInt(color.substr(i * 2, 2), 16);
                    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
                    rgb += ("00" + c).substr(c.length);
                }
            }

            return rgb;
        },
        hexToRGBA(hex, opacity) {
            hex = hex.replace("#", "");
            // Extract the RGB values
            const red = parseInt(hex.substring(0, 2), 16);
            const green = parseInt(hex.substring(2, 4), 16);
            const blue = parseInt(hex.substring(4, 6), 16);

            // Create the RGBA color string
            return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
        },
    },
}